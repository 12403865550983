@import '../../../styles/colors';
@import '../../../styles/mixins';

.content {
  display: flex;
  flex-direction: row;
  margin: 40px auto;
}

.content > * + * {
  margin-left: 40px;
}

.card {
  flex: 1;
  padding: 24px;
  border: 1px solid $blue-04;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    @include heading-18;
    padding-right: 19px;
  }
}

.iconContainer {
  background-color: rgba($blue-02, 0.08);
  border-radius: 8px;
  width: 82px;
  height: 82px;
  min-width: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
}

@media (max-width: 1024px) {
  .card p {
    font-size: 13px;
  }
  
  .iconContainer { 
    width: 50px;
    height: 50px;
    min-width: 50px;

    img {
      width: 29px;
      height: 29px;
    }
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .content > * + * {
    margin-top: 16px;
    margin-left: 0;
  }

  .card p {
    font-size: 14px;
  }
  
}