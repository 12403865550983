@import '/styles/colors';

.modal{
  position: fixed;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 20px;
  right: 20px;
  z-index: 1000002;
  padding: 40px 32px;
  border-radius: 8px;
  filter: drop-shadow(4px 5px 15px rgba(20, 21, 24, 0.07));
  max-width: 417px;
}

@media screen and (max-width: 1200px) {
  .modal{
    bottom: 10px;
    right: auto;
  }


}

@media screen and (max-width: 768px) {
  .modal{
    right: 0;
    bottom: -20px;
    width: 100%;
    border-radius: 0;
    margin: 0;
    max-width: 768px;
  }
}
.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #030314;
  margin-bottom: 24px;
}

.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #646981;
  margin-bottom: 24px;
}

.description a{
  color: $blue-01;
  text-decoration: underline;
}
