@import '/styles/colors';
@import '/styles/mixins';

.header {
  @include heading-20_bold;
}

.modalList {
  width: fit-content;

  button {
    border: 1px solid red;
  }
  ul {
    list-style-type: none;
  }

  tr > td + td {
    padding-left: 10px;
  }

  li:nth-child(even) {
    background: rgb($blue-04, 0.3);
    border-radius: 6px;
  }
  li {
    @include body-14;
    color: $gray-03;
    padding: 8px 8px;
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    font-size: 18px;
  }

  .modalList {
    width: 100%;
    * {
      display: flex;
      flex-direction: column;
      li {
        font-size: 12px;
      }
    }
  }
}
