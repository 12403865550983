@import '/styles/colors';
@import '/styles/mixins';

.title {
  @include heading-18;
  color: $gray-02;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  padding-bottom: 29px;
}
.subTitle {
  @include body-14;
  color: $gray-04;
}

.condition {
  max-width: 40%;
}
.link {
  align-self: flex-end;
  align-items: flex-end;
  max-width: 35%;
  padding-bottom: 10px;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 60px;
}

@media screen and (max-width: 1024px) {
  .content {
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 100%;
  }
  .condition {
    max-width: 100%;
  }
  .subTitle {
    max-width: 100%;
    padding-bottom: 32px;
  }
  .link {
    align-self: center;
    max-width: 100%;
  }
}
