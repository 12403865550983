.loadingContainer {
  position: relative;
}
.spinner {
  display: none;
  position: absolute;
  background-color: rgba(white, 0.6);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: background-color .4 ease-in-out;
}

.spinnerLoading {
  display: flex;
}