@import '/styles/colors';
@import '/styles/mixins';

.titleCards {
  @include heading-20;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  padding-bottom: 17px;

  color: $gray-01;
}
.title {
  @include heading-16;
  color: $blue-02;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.subTitle {
  @include heading-34;
  color: $gray-01;
  font-weight: 500;
  font-size: 34px;
  line-height: 135%;
  letter-spacing: 0.02em;
  padding-bottom: 29px;
}

.description {
  @include body-16;
  color: $gray-04;
}

.condition {
  max-width: 40%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 60px;
}

.container {
  display: flex;
  flex-direction: column;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  max-width: 593px;

  justify-content: space-around;
}

.card {
  min-width: 45%;
  flex: 1;
  padding: 34px 40px;
  border: 1px solid $blue-04;
  border-radius: 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;

  p {
    @include heading-18;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .cardsContainer {
    width: 100%;
    max-width: 100%;
  }

  .card {
    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .content {
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 100%;
  }

  .condition {
    max-width: 100%;
  }

  .subTitle {
    max-width: 100%;
    padding: 0 16px 0 16px;
    padding-bottom: 32px;
    font-weight: 600;
  }

  .description {
    margin-bottom: 32px;
  }

  .card {
    width: 100%;
    padding: 10% 15px;
  }

  .titleCards {
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
  }
}
