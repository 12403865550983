@import '/styles/colors';
@import '/styles/mixins';

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 40px;
}
.link {
  text-decoration: underline;
  color: #3ce69e;
}
.tableContainer {
  border: 1px solid $blue-04;
  border-radius: 6px;
  margin-bottom: 40px;

  table {
    @include body-16;
    color: $gray-04;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 12px 32px;
  }

  tr:nth-child(even) {
    background-color: rgba($blue-04, 0.3);
  }
}

.tableDescription {
  @include body-16;
  color: $gray-04;
  margin-bottom: 40px;
}

.tableTitle {
  @include heading-18;
  padding: 32px;
  font-weight: 400;
}

.blueCard {
  background-color: $blue-01;
  border-radius: 8px;
  padding: 48px;
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
}

.cardTitle {
  @include heading-20-bold;
  color: white;
  margin-bottom: 22px;
}

.cardDescription {
  @include body-14;
  color: white;
}

.cardIcon {
  width: 46px;
  height: 46px;
  margin-right: 28px;
}

.columnDescription {
  @include body-14;
  color: $gray-04;
}

.tables {
  margin-top: 40px;
}

@media screen and (max-width: 800px) {
  .tableDescription,
  .tableTitle,
  .cardTitle,
  .columnDescription,
  .cardDescription {
    text-align: center;
  }

  .tableContainer table,
  .cardDescription,
  .columnDescription,
  .tableDescription {
    font-size: 12px;
  }

  .tableTitle {
    font-size: 11.55px;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .blueCard {
    flex-direction: column;
    align-items: center;

    .cardIcon {
      margin-right: 0;
      margin-bottom: 13px;
    }
  }
}
