@import "/styles/mixins";
@import "/styles/colors";

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image {
  z-index: -1;
  background-color: $blue-01;
}

.slider {
  margin: auto;
  max-width: 1240px;
  width: calc(100% - 40px);
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 571px;
}

.slide {
  width: 100vw;
  position: relative;
  transition: opacity 0.4s ease-in-out;
  flex-shrink: 0;

  a[type="button"] {
    min-width: 280px;
    padding: unset 18px unset 18px;
    width: 0;
    p {
      color: $blue-01;
    }
  }
}

.heading {
  @include heading-16;
  color: white;
}

.title {
  @include heading-40;
  margin-top: 16px;
  color: white;
  max-width: 50%;
}

.controller {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  align-items: center;
  right: 0;
}

.slideButton {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  color: white;
  outline: none;
}

.slideButtonActive {
  background-color: white;
  color: $blue-01;
}

.arrowController {
  user-select: none;
  margin-left: 16px;
  background-color: white;
  display: flex;
  border-top-left-radius: 6px;
  height: 71px;
  width: 216px;
  padding-left: 16px;

  div {
    padding: 16px 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    img {
      height: unset;
    }
  }
}

@media screen and (max-width: 1024px) {
  .heading {
    font-size: 12px;
  }

  .title {
    font-size: 24px;
    line-height: 120%;
    margin-top: 10px;
  }

  .slider {
    height: 362px;
  }

  .content {
    margin: 60px auto;
  }

  .slide {
    a[type="button"] {
      min-width: 200px;
      width: 0;
      height: 50px;
      p {
        color: $blue-01;
      }
    }
  }

  .arrowController {
    width: 102px;
    height: 45px;
  }
}

.textContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .slider {
    height: unset;
    width: 100%;
  }

  .title {
    max-width: 100%;
  }

  .heading,
  .title {
    text-align: center;
  }

  .content {
    align-items: center;
    justify-content: flex-end;
  }
}
