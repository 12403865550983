@import '../../../styles/mixins';
@import '../../../styles/colors';

.container {
  position: relative;
}

.image {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: -1;
  width: 100%;

  div {
    border-radius: 16px;
  }

  h1 {
    @include heading-16;
  }
}

.phoneContainer {
  margin-top: 24px;
}

.phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $blue-01;
  margin-top: 8px;

  h1 {
    margin-left: 14px;
  }
}

.formDotsTop {
  display: none;
}

.formDotsBottom {
  display: none;
}

.form {
  padding: 65px;

  .formContent {
    position: relative;
    padding: 64px;
    background-color: white;
    width: 65%;
    border-radius: 8px;
    box-shadow: 0px 24px 32px -12px rgba(0, 0, 0, 0.08);

    h1 {
      @include heading-30;
      color: $gray-02;
    }

    h2 {
      @include heading-18;
      color: $gray-03;
    }
  }
}

@media screen and (max-width: 1024px) {
  .image h1 {
    font-size: 14px;
  }

  .formContent {
    box-shadow: 0px 15.0575px 56.4658px rgba(194, 194, 194, 0.15);
  }
}

@media screen and (max-width: 768px) {
  .formDotsTop {
    display: unset;
    position: absolute;
    top: -40px;
    right: 0;
    z-index: -1;
  }

  .formDotsBottom {
    display: unset;
    position: absolute;
    bottom: -40px;
    right: 0;
    z-index: -1;
  }

  .image {
    display: none;
  }

  .form {
    padding: 0;
    text-align: center;

    .formContent {
      width: 100%;
      padding: 33px;
      margin-bottom: 60px;
    }
  }
}
