@import '/styles/colors';
@import '/styles/mixins';
.listFooter {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  float: left;
  max-width: 1240px;
  width: calc(100% - 0px);
}

.listItem {
  align-items: center;
  @include body-16;
  font-size: 16px;
}

.footer {
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.content {
  display: flex;
  flex-direction: row;
  max-width: 1240px;
  width: calc(100% - 40px);
  padding: 50px 0;
}
.contentLine {
  border-bottom: 1px solid $blue-04;
  max-width: 1240px;
  width: calc(100% - 40px);
}
.contentColumn {
  flex-direction: column !important;
}
.title{
  @include body-16;
  font-size: 20px;
  color: $gray-01;
  line-height: 140%;
  font-weight: 600;
  margin-bottom: 16px;
  letter-spacing: 0.02em
}
.footerCopyright {

  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.contentCopyright {
  border-top: 1px solid $blue-04;
  display: flex;
  flex-direction: row;
  max-width: 1240px;
  width: calc(100% - 40px);
  padding: 32px 0;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.link {
  color: $gray-04;
}

.description {
  @include body-16;
  padding: 5px 0;
  font-weight: 600;
  line-height: 150%;
  color: $gray-01;
}

.subDescription {
  @include body-14;

  color: $gray-04;
  font-size: 14px;
}
.content > div {
  flex: 1;
}
.content > * + * {
  margin: 0 0 0 19px;
}

.contentBusiness {
  padding: 43px 0 36px;
  width: calc(100% - 40px);
  max-width: 1240px;
  display: flex;

  justify-content: space-between;
}
.contentCopyright > * + * {
  margin: 0 0 0 20px;
}

.column {
  width: 100%;
}

.column h3 {
  font-size: 20px;
  margin-bottom: 16px;
}

.column p {
  color: $gray-04;
}

.column a {
  color: $gray-04;
}

.listFooter > * + * {
  margin-top: 18px;
}

.footerText > * + * {
  margin-top: 15px;
}

.footerTextIcon {
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  color: $gray-04;
}
.footerTextIcon img {
  align-self: flex-start;
}
.footerTextIcon > * + * {
  margin: 0px 0px 0 15px;
}

.businessName {
  font-size: 16px;
  color: $gray-01;
  font-weight: 600;
}

.footerText {
  color: $gray-04;
}

@media screen and (max-width: 1200px) {
  .content {
    flex-direction: column;
  }
  .contentBusiness {
    flex-direction: column;
  }
  .contentCopyright {
    flex-direction: column;
    text-align: center;
    font-size: 12px;
  }

  .content > * + * {
    margin: 20px 0;
    text-align: center;
  }
  .contentBusiness > * + * {
    margin: 20px 0;
  }
  .contentCopyright > * + * {
    margin: 20px 0;
  }

  .footer {
    text-align: center;
  }
  .footerTextIcon {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .footerCopyright {
    .footerTextIcon {
      flex-direction: row;
      margin: 0px 0px 0px 0px;
    }
  }
  .footerTextIcon img {
    align-self: center;
  }
  .footerTextIcon div {
    margin-left: 0;
  }
}
