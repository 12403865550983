@import '/styles/colors';
.separator {
  border-bottom: 1px solid $blue-04;
  margin: 60px 0 80px;
}

@media screen and (max-width: 1024px) {
  .separator {
    margin: 64px 0;
  }
}
