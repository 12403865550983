@import '/styles/colors';
@import '/styles/mixins';

.about {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  background: #ffffff;
  margin-left: -50px;
  box-shadow: 0px 40px 72px -32px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.content {
  width: 50%;
  margin-left: 40px;

}

.contentAll {
  margin-left: 40px;
}

.content * p {
  color: $gray-04;
}

.content * h2,
.content * h3,
.content * h4,
.content * h5,
.content * h6 {
  color: $gray-02;
  margin-top: 40px;
  font-weight: bold;
}

.title {
  @include heading-30;
  color: $gray-01;
  font-weight: bold;
}

.image {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemTitle {
  @include heading-20-bold;
  color: $gray-02;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemTitle p {
  margin-left: 8px;
}

.heading {
  @include heading-16;
  text-transform: uppercase;
  color: $blue-01;
}

@media screen and (max-width: 1024px) {
  .content {
    width: 60%;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .about {
    text-align: center;
    flex-direction: column;
  }

  .image {
    flex-direction: column;
    position: relative;
    margin-bottom: 40px;
  }

  .icon {
    position: absolute;
    bottom: -18px;
    right: 40px;
  }

  .content {
    width: 100%;
    margin-left: 0;
  }
}
