@import '/styles/colors';
@import '/styles/mixins';

.contentExpanded {
  margin-top: 10px !important;
  max-height: 9999px !important;
  opacity: 1 !important;
}

.arrowUp {
  transform: rotate(180deg);
}

.contentHeader {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    transition: transform 0.2s ease-in-out;
  }
}

.contentExpandable {
  @include body-16;
  padding: 22px 32px;
  .content {
    margin-top: 0;
    color: $gray-01;
    max-height: 0;
    overflow: hidden;
  }

  a {
    color: $blue-01;
  }
}
