@import '/styles/colors';
@import '/styles/mixins';

.investmentTime {
  display: flex;

  align-items: center;
  color: $blue-02;
}

.investmentTimeCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 6px;

  div[role='label'] {
    @include body-14;
    color: $gray-03;

    margin: 12px 12px 12px 0;
  }
}

.chartFrame {
  width: 60%;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  max-width: fit-content;

  color: $blue-03;
}

.chart {
  position: relative;
  width: 100%;
  display: flex;
  gap: 5%;
  height: 60%;

  align-items: flex-end;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba($blue-03, 0.23);
}
.chartLabel {
  z-index: 1;
}

.chartInfos {
  @include body-14;
  font-size: 12px;
  display: flex;
  width: 100%;
  height: auto;
  text-align: center;
  color: white;
  gap: 5%;
  align-items: flex-start;
  justify-content: space-evenly;
  user-select: none;
}
.chartInfoProfit {
  @include heading-18;
  font-size: 18px;
  color: #9df3ce;
}
.chartInfo {
  user-select: none;
  justify-content: center;
  width: 100px;

  img {
    margin: 7px 0;
    width: 100%;
    height: 7px;
  }
}
.chartInfoLabel {
  @include body-14;
  color: $blue-04;
  font-size: 13px;
}
.chartInfoValue {
  @include heading-20-bold;
  font-size: 20px;
}
.barGroup {
  @include body-14;
  user-select: none;
  font-weight: 600;
  height: 100%;
  max-width: 110px;
  width: 110px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  div {
    padding: 5px;
    color: #e2f0fd;
  }
  p {
    border-radius: 8px;
  }
  .bar {
    z-index: 1;
  }
  .bar:hover {
    cursor: help;
    box-shadow: 0px -1px 12px 4px rgba(255, 255, 255, 0.75);
  }
}

.barLine {
  position: absolute;
  border-bottom: 1px dashed $blue-02;
  width: 100%;
  left: 0;
}

@media (max-width: 1024px) {
  .barGroup {
    font-size: 90%;
    width: 20%;
  }
  .chartInfos {
    border-bottom: 1px solid rgba($blue-03, 0.23);
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .chartInfo {
    width: 130px;
    font-size: 12px;
  }
  .chartFrame {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .chart {
    height: 300px;
  }

  .info {
    text-align: center;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .barGroup {
    font-size: 80%;
    width: 20%;
  }
  .chartInfo {
    width: 80px;
    font-size: 9px;
  }
}

@media (max-width: 500px) {
  .barGroup {
    font-size: 8px;
    width: 20%;
  }
  .chartInfoLabel {
    font-size: 9px;
  }
  .chartInfoProfit {
    font-size: 12px;
  }
  .chartInfoValue {
    @include heading-20-bold;
    font-size: 12px;
  }
  .chartInfo {
    width: 60px;
    font-size: 8px;
  }
}
