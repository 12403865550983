@import '/styles/colors';
@import '/styles/mixins';


.componentTitle,
.title {
  @include heading-30;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: $gray-01;
  padding-bottom: 12px;
}
.componentSubTitle {
  @include body-16;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: $gray-04;
  padding-bottom: 24px;
}

.componentHearder
{ 
  display: inline-flex; 
  width: 100% ; 
  align-items: center;
}
.componentTitleSubSubTitle{
  flex: 2;
}
.search {
  flex: 1;
  text-align: end;
  padding-bottom: 15px;
  margin-left: 60px;
}
.componentFrame::after {
  content: '';
  flex: 0 1 32%;
}
.componentFrame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .cardFrame {
    margin-bottom: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 395px;
    min-width: 395px;
    min-height: 350px;
    padding: 20px 20px;
    border-radius: 8px;
    border: 1px solid $blue-04;
    .cardColumn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-content: flex-start;
      .icon {
        width: 35px;
        height: 35px;
        object-fit: none;
      }
      .card {
        margin-left: 20.5px;
        width: 100%;
        height: 100%;
        .title {
          @include heading-20-bold;
          font-weight: 600;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: 0.01em;
          color: $gray-02;
          max-width: 338px;
          margin-bottom: 22px;
        }
        .description {
          @include body-16;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          color: $gray-04;
          margin-bottom: 34px;
        }
      }
    }
  }
}

.componentFrame > * + * {
  margin-left: 0px;
}
.button{
  text-align: center;
}
@media screen and (max-width: 1240px) {
  .component {
    align-items: center;
    .componentFrame {
      flex-direction: column;
      align-items: center;
      .cardFrame {
        flex-direction: row;
        width: 100%;
        min-height: 230px;
        max-width: 100%;
        padding: 30px;
        .cardColumn {
          .title {
            @include heading-15;
            padding-bottom: 8px;
            margin: 0;
          }
          .description {
            @include body-14;
            line-height: 160%;
            color: $gray-04;
            padding-bottom: 8px;
            margin: 0;
          }
          .icon {
            width: 35px;
            height: 35px;
            object-fit: none;
          }
        }
      }
    }
  }

  .componentTitle {
    @include heading-20-bold;
    line-height: 125%;
    letter-spacing: 0.02em;
  }

  .componentSubTitle {
    @include body-14;
    line-height: 160%;
  }

  .component .componentTitle,
  .componentSubTitle {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .component .componentTitle,
  .componentSubTitle {
    text-align: center;
  }

  .component {
    .componentFrame {
      .cardFrame {
        min-width: auto;
      }
    }
  }

  .search {
    flex: 1;
    text-align: end;
    padding-bottom: 35px;
    margin-left: 0px;
  }

  .componentHearder
  { 
    display: inline-block;
    align-items: center;
    text-align: center;
  }
}

