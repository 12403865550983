@import '/styles/colors';
@import '/styles/mixins';

.title {
  @include heading-16;
  color: $blue-02;
  text-transform: uppercase;
}

.subtitle {
  @include heading-30-bold;
  color: $gray-01;
  padding-bottom: 20px;
}

.description {
  @include body-16;
  color: $gray-04;
  margin-bottom: 64px;
}

@media screen and (max-width: 1024px) {

  .frame {
    h1{
      white-space: pre-line;
    }

    padding: 0;
    align-items: center;
    text-align: center;
  }
  .subtitle {
    max-width: 100%;
  }
}
