@import '/styles/colors';
@import '/styles/mixins';

.investmentTypeTitle {
  @include heading-34;
  color: $gray-02;
  font-weight: bold;
  margin: 80px 0 40px;
}

.itemDescription {
  @include body-16;
  color: $gray-04;
  padding-top: 18px;
}

.investmentTypeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

@media screen and (max-width: 768px) {
  .investmentTypeTitle {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  .item {
    text-align: center;
  }

  .investmentTypeContainer {
    grid-auto-flow: row;
    grid-template-columns: unset;
    row-gap: 50px;
  }
}
