@import '/styles/colors';
@import '/styles/mixins';

.card {
  label {
    color: $gray-03;
  }
}

.card div[role='group'] {
  margin: 25px 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: flex-end;
  label {
    padding: 0 5px;
    margin: 12px 12px 12px 0;
    white-space: nowrap;
    width: 300px;
  }
  input {
    text-align: end;
  }

  div,
  select {
    justify-content: flex-end;
    path {
      color: $blue-02;
    }
    svg {
      align-content: flex-end;
      position: relative;
    }
  }
}

.greenCard {
  background-color: rgba(red($green-03), green($green-03), blue($green-03), 0.6);
  border-radius: 6px;
  margin-top: 40px;
  padding: 47px 33px;

  .labelCard {
    @include heading-18;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: $gray-03;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    padding-bottom: 30px;
    margin-bottom: 18px;

    border-bottom: 1px solid rgba(red(#26d891), green(#26d891), blue(#26d891), 0.2);
    align-items: center;
    .monetaryValue {
      margin-top: 2px;
    }
    .imgPopover {
      width: fit-content;
    }
    div {
      @include heading-28;
      *:focus {
        box-shadow: none;
      }
      color: $gray-01;
      font-weight: 600;
      font-size: 28px;
      line-height: 140%;
      letter-spacing: 0.01em;
    }
  }
  .greenCardInfo {
    @include body-14;
    color: $gray-04;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .card {
    label {
      font-size: 14px;
    }
  }

  .greenCard {
    padding: 24px;
    .labelCard {
      @include heading-18;
      padding-bottom: 13px;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      img {
        width: 14px;
      }
      div {
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
      }
    }
  }
}
