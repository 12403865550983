.categoriesSwiper {
  width: 100%;
  max-width: 1136px;
  height: 100%;
}

.categoriesItemSwiper {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 451px;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    transition: width 0.4s ease-in-out;

    &:hover {
      width: 450px !important;
    }
  }

  @media screen and (max-width: 425px) {
    height: 200px;
  }

  @media screen and (max-width: 425px) and (min-width: 320px) {
    img {
      height: auto;
    }
  }
}

.categoriasSwiperBlackAndWhite {
  max-width: 1213px;
}

.categoriesItemSwiperBlackAndWhite {
  cursor: pointer;
  width: auto;
  filter: grayscale(100%);

  &:hover {
    transition: 0.5s;
    filter: grayscale(0%);
  }
}
