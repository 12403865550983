.framePrincipalReverse,
.framePrincipal {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 60% 1fr 34%;
  width: 100%;
  height: 100%;
}

.frameOneImage{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 95% 1fr;
  width: 100%;
  height: 100%;
}

.framePrincipalReverse {
  direction: rtl;
  padding-bottom: 75px;

}
.frameImage1 {
  display: grid;
  grid-column: 1;
  align-self: flex-end;
  height: 100%;

  grid-template-rows: 7% 110% 0px;
}
.frameImage1Reverse {
  display: grid;
  grid-column: 1;
  align-self: flex-end;
  height: 100%;
  grid-template-rows: 0px 93% 7%;
}

.frameImage2 {
  width: 100%;
  grid-column: 3;
  display: grid;
  grid-template-rows: 0px 70% 9% 23% 1fr;
}

.frameImage2Reverse {
  width: 100%;
  grid-column: 3;
  display: grid;
  grid-template-rows: 1fr 55% 9% 23% 0px;
}

.mainImage {
  grid-row: 2;
}
.sideImage1 {
  grid-row: 2;
}

.frameImage3 {
  display: grid;
  grid-template-columns: 48.4% 3.2% 48.4%;
  grid-row: 4;
}

.frameImage4 {
  display: grid;
  grid-row: 4;
  grid-template-columns: 56.28% 8.56% 35.16%;
}

.sideImage3 {
  grid-column: 3;
}
.sideImage3 img {
  border-radius: 8px;
  object-fit: none;
  width: 100%;
  height: 60%;
}

.sideImage2 img {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  grid-column: 1;
}

.frameImage4 .sideImage2 img {
  object-fit: cover;
}

.frameImage3 .sideImage2 img {
  object-fit: none;
}

.mainImage img,
.sideImage1 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
@media screen and (max-width: 1024px) {
  .framePrincipalReverse,
  .framePrincipal {
    margin: 40px 0 140px;
  }
  .frameImage2 {
    grid-template-rows: 0px 55% 9% 1fr;
  }
  .frameImage2Reverse {
    grid-template-rows: 1fr 55% 9% 23% 0px;
  }
  .frameImage3 {
    grid-template-columns: 72% 1fr;
  }
}
