@import '../../../styles/colors';
@import '../../../styles/mixins';

.content {
  border-top: 1px solid $blue-04;
  padding: 80px 0;
}

.title {
  @include heading-34;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  font-weight: bold;
  color: $gray-01;
  margin-bottom: 68px;
}

.item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.itemContent {
  a {
    padding: 0 24px;
  }
  margin: 0 0 0 10%;
}

.itemReverse {
  flex-direction: row-reverse;
}

.itemsContainer > * + * {
  margin-top: 64px;
}

.itemReverse > * + * {
  margin: 0 10% 0 0;
}

.imageContainer {
  width: 38%;
  min-width: 38%;
  border-radius: 8px;
  overflow: hidden;
}

.heading {
  @include heading-16;
  text-transform: uppercase;
  color: $gray-04;
}

.description {
  @include body-16;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: $gray-04;
  margin-bottom: 34px;
}

.itemTitle {
  @include heading-30;
  margin-bottom: 12px;
  color: $gray-02;
}

.text {
  @include body-16;
  color: $gray-04;
  margin-bottom: 38px;
}

@media (max-width: 1024px) {
  .title {
    font-size: 20px;
  }

  .heading {
    font-size: 12px;
  }

  .itemTitle {
    font-size: 20px;
  }

  .text {
    font-size: 14px;
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .item {
    text-align: center;
    flex-direction: column;
  }

  .itemContent {
    margin: 24px;
  }

  .imageContainer {
    width: 100%;
  }
}
