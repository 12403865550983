@import '/styles/colors';
@import '/styles/mixins';

.container {
  margin: 64px 0;
}

.menu {
  flex: 0.5;
}

.questions {
  margin-top: 27px;
  border-radius: 8px;
  border: 1px solid $blue-04;
}

.questionsContainer {
  flex: 1;

  span {
    @include heading-20-bold;
  }
}

.questions > * + * {
  border-top: 1px solid $blue-04;
}

.menuItem {
  @include heading-18;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: $gray-03;
  cursor: pointer;
  padding: 10px 0;

  .dot {
    width: 8px;
    height: 8px;
    background-color: $gray-04;
    margin-right: 22px;
    border-radius: 100%;
  }
}

.active {
  color: $blue-01;

  .dot {
    background-color: $green-01;
  }
}

.content {
  display: flex;
  flex-direction: row;
}

.questionsFooter {
  margin-top: 27px;
  h3 {
    @include heading-18;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-left: 20px;
    }
  }

  div,
  a {
    color: $blue-01;
  }
}

.answerExpanded {
  margin-top: 10px !important;
  max-height: 9999px !important;
  opacity: 1 !important;
}

.arrowUp {
  transform: rotate(180deg);
}

.questionHeader {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    transition: transform 0.2s ease-in-out;
  }
}

.questionExpandable {
  @include body-16;
  padding: 22px 32px;

  .answer {
    margin-top: 0;
    color: $gray-01;
    max-height: 0;
    overflow: hidden;
  }

  a {
    color: $blue-01;
  }
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }

  .menu {
    margin-bottom: 24px;
  }
}
