@import '/styles/colors';
@import '/styles/mixins';

.subdescription {
  @include body-14;
  color: $gray-04;
}

.frameText {
  @include body-16;

  padding-right: 72px;
  max-width: 518px;
}

.link {
  @include heading-18;
  line-height: 160%;
  letter-spacing: 0.02em;
  margin: 36px 0;
}
.link a {
  align-items: center;
  display: flex;
}
.link img {
  margin-left: 10px;
}

@media screen and (max-width: 1024px) {
  .frameText {
    max-width: 100%;
    padding: 0 20px;
    align-items: center;
  }
  .frameText .link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
