@import '../../styles/mixins';
@import '../../styles/colors';

.inputsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputFull {
  width: 100%;
}

.inputHalf {
  width: 48%;
}

.formButton {
  margin-top: 19px;
}

@media screen and (max-width: 1024px) {
  .inputHalf {
    width: 100%;
  }
}
