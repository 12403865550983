.customBullet {
  background: white !important;
  opacity: 1;
}

.customBulletActive {
  background: #0D71F0 !important;
  opacity: 1;
}

.customCarousel {
  height: inherit;
  max-width: 1000px;
  display: flex;
  align-items: center;
}

.customCarouselItem {
  height: inherit;
}
