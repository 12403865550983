@import '/styles/colors';
@import '/styles/mixins';

.divline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-around;

  .label {
    width: 60%;
  }
  .label,
  .value {
    @include body-16;
    color: black;

    font-size: 13px;
    align-items: center;
    padding: 0 10px 0;
    display: flex;
    flex-direction: row;
    text-align: left;
  }
  .value {
    width: 35%;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 160%;
  }
}

.divline:nth-child(even) {
  background: rgb($blue-04, 0.4);
  border-radius: 4px;
}

.divline > * + * {
  margin: 8px 0 10px;
}
.body {
  padding: 16px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popoverHeader {
  h1 {
    @include heading-18;
    color: $blue-01;
    text-align: center;
    align-self: center;
  }
  display: flex;
  flex-direction: row;

  align-self: flex-start;
  opacity: 1;
  margin: 22px 0px;
}
