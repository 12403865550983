@import '/styles/colors';
@import '/styles/mixins';

.frameColumn {
  align-content: center;
  display: flex;
  flex-direction: row;
}

.frameColumn2 > * + * {
  margin-top: 27px;
}
.frameColumn > * + * {
  margin-left: 27px;
}

.frameColumn2 {
  align-content: center;
  align-items: center;
  width: 100%;
}

.frame {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.cards {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e2f0fd;
}

.cards > * + * {
  border-top: 1px solid #e2f0fd;
}

.cards div {
  @include body-16;
  color: $gray-03;
  font-size: 14px;
  line-height: 140%;
}

.cardDownload {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $blue-04;

  padding: 14px;
  border-radius: 8px;
  margin: 10px 0;
  justify-content: space-between;
}

.cardDownload a {
  color: #e2f0fd;
  margin-left: 10px;
}
.cardDownload h1 {
  margin: 8px;

  color: $blue-04;
}
.cardDownload p {
  @include body-16;
  color: $gray-02;
  line-height: 160%;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .frameColumn {
    flex-direction: column;
    align-items: center;
  }
  .cardDownload {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
  }

  .frameColumn2 > * + * {
    margin-top: 27px;
  }
  .frameColumn > * + * {
    margin-top: 27px;
    margin-left: 0;
  }
}
