@import '../../../styles/colors';
@import '../../../styles/mixins';

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;

  div {
    flex: 1;
  }

  .description {
    @include body-16;
    color: $gray-04;
    margin-bottom: 24px;
  }
}

.content > * + * {
  margin: 0 0 0 64px;
}

.heading {
  @include heading-16;
  color: $blue-01;
  text-transform: uppercase;
}

.title {
  @include heading-34;
  margin-bottom: 24px;
}

.image {
  position: relative;
  height: 512px;
  border-radius: 8px;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    text-align: center;
  }

  .image {
    position: unset;
    width: 100%;
    img {
      height: 100%;
    }
  }

  .content > * + * {
    margin: 27px 0 0 0;
  }

  .logo {
    margin-top: -32px;
  }

  .heading {
    font-size: 12px;
  }

  .title {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
  }
}
