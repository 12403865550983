@import '/styles/colors';
@import '/styles/mixins';

.listWithoutDetails,
.listWithDetails {
  padding-bottom: 32px;
}
.label {
  :hover {
    color: $blue-01;
  }
}

.link {
  text-decoration: underline;
}

.labelWithDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
  .label {
    @include heading-20_bold;
    color: $gray-01;
    padding-bottom: 8px;
  }
  .details {
    @include body-16;
    color: $gray-04;
  }
}

.item2,
.item {
  @include heading-16;
  color: $gray-02;
  padding: 25px 0;
  display: flex;
}
.listWithoutDetails > * {
  border-bottom: 1px solid $blue-04;
}
.listWithDetails > * + * {
  border-top: 1px solid $blue-04;
}

.icon {
  width: fit-content;
  height: fit-content;
  img {
    width: 35px;
    height: 35px;
  }
  padding-right: 20px;
}

@media screen and (max-width: 1024px) {
  .item {
    display: flex;
    flex-direction: column;
    text-align: center;
    .icon {
      display: none;
    }
    .labelWithDetails {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
