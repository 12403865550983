@import "/styles/colors";
@import "/styles/mixins";

.itemTitle {
  @include heading-20-bold;
  color: $gray-02;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemTitle p {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .itemTitle {
    flex-direction: column;
    align-items: center;

    p {
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px;
      margin-left: 0px;
    }
  }
}
