@import '/styles/colors';
@import '/styles/mixins';

.tabList {
  button {
    box-shadow: 'none !important';
    color: $gray-03;
    opacity: 0.6;
    @include heading-18;
    white-space: nowrap;
    padding-bottom: 15px;
  }

  button[aria-selected='true'] {
    color: $blue-02;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .tabList {
    button {
      font-size: medium;
    }
  }
}

@media (max-width: 425px) {
  .tabList {
    button {
      font-size: 10px;
    }
  }
}
