@import '/styles/colors';
@import '/styles/mixins';

.backgroundImage {
  display: none;
}
.frame {
  background-image: url('/background.svg');
  background-repeat: no-repeat;
  background-position: right top;

  display: flex;
  flex-direction: row;

  margin-top: 20px;
  margin-bottom: 180px;

  .images {
    position: relative;
    display: flex;
    width: fit-content;

    .icon1,
    .icon2 {
      border-radius: 8px;
      object-fit: fill;
      align-items: center;
      align-content: center;
      text-align: center;
      align-self: center;
      padding: 25px;

      max-width: 94px;
      position: absolute;
      z-index: 1;
    }

    .icon1 {
      background-color: rgba(253, 197, 109, 0.3);
      top: 84px;
    }

    .icon2 {
      background-color: $green-03;
      top: 246px;
      left: 38px;
    }

    .imagens {
      padding-left: 110px;
    }
  }

  .frameSimulator {
    padding: 70px;
    height: fit-content;
    width: 607px;
    position: relative;

    top: 53px;
    left: -85px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 24px 90px rgba(194, 194, 194, 0.35);
    p {
      @include heading-30;
      color: $gray-01;
      margin-bottom: 15px;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  margin-top: 90px;
  margin-left: 110px;

  p {
    @include heading-20;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #4e4b59;
  }
  div {
    border: 0;
    padding: 4px 0;
    max-width: 340px;
    @include heading-28;
    text-align: left;
    align-items: center;
    color: $blue-02;
    line-height: 140%;
  }
}
.list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: 180px;
  padding-bottom: 39px;

  p {
    @include heading-20;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    padding-bottom: 10px;
  }

  img {
    padding: 0;
    width: 23px;
    height: 23px;
    margin-right: 17px;
  }
  div {
    border: 0;
    padding: 4px 0;
    @include body-14;
    text-align: left;
    align-items: center;
    color: $gray-04;
    font-size: 14px;
    line-height: 140%;
  }
}

@media screen and (max-width: 1024px) {
  .content {
    width: 100%;
  }
  .backgroundImage {
    display: flex;
    height: 100px;
    img {
      width: 70%;
      object-fit: none;
    }
    justify-content: flex-end;
  }
  .frame {
    .images {
      display: none;
    }
    margin: 0;
    background-image: none;
    padding: 0 20px;
    box-shadow: 0px 24px 90px rgba(194, 194, 194, 0.35);
    margin-bottom: 0;
    width: 100%;
    background-position: right top, right bottom -200px;
    flex-direction: column-reverse;

    .frameSimulator {
      padding: 0;

      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 32px;
      }
      position: relative;
      left: 0;
      right: 0;
      top: 53px;
      margin-bottom: 77px;
      align-self: center;
      text-align: center;
      width: 100%;
      box-shadow: none;
    }
  }

  .list {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding-bottom: 100px;
    p {
      font-size: 14px;
      padding-bottom: 15px;
    }
    div {
      border: 0;
      padding: 4px 0;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      color: $blue-02;
      line-height: 140%;
    }
  }
}
