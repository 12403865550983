@import '../../styles/colors';
@import '../../styles/mixins';

.required {
  color: #ed143d;
}

.label {
  @include body-16;
  color: $gray-01;
  margin-bottom: 4px;
  margin-top: 24px;
}