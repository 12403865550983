@import '/styles/mixins';
@import '/styles/colors';

.simulatorInput {
  @include heading-28;
  color: $blue-02;
  flex: 0;
  min-width: unset !important;
  width: 250px !important;
  outline: none;
}

.input {
	width: 100%;
	min-width: 0;
	outline: 0;
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	font-size: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	height: 2.5rem;
	border-radius: 0.375rem;
	border: 1px solid;
	border-color: inherit;
	background: inherit;
}

.input[aria-invalid="true"], .input[data-invalid] {
	border-color: #E53E3E;
	box-shadow: 0 0 0 1px #E53E3E;
}

@media (max-width: 462px)
{
  .simulatorInput
   {
    font-size: 16px;
    width: 150px !important;
   }
}
