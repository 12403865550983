@import "/styles/colors";
@import '/styles/mixins';
.link {
  color: $blue-01;
}

.cookiesFooter {
  @include body-16;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: $blue-02;
  text-decoration-line: underline;
}
