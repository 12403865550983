@import '/styles/colors';
@import '/styles/mixins';

.group,
.groupReverse {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 33% 1fr 62%;
  padding-bottom: 40px;
}

.groupImageTextReverse {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 62% 1fr 33%;
  padding-bottom: 40px;
}
.groupOnlyOneImage {
  grid-template-columns: 62% 1fr 33%;
  display: grid;
  justify-content: space-between;
  padding-bottom: 40px;
}
.groupOnlyOneImageReverse {
  grid-template-columns: 33% 1fr 62%;
  display: grid;
  justify-content: space-between;
  padding-bottom: 40px;
}


.column1 {
  grid-column: 1;
}
.column2 {
  grid-column: 3;
}

@media screen and (max-width: 1024px) {
  .column1,
  .column2 {
    grid-column: 1;
  }
  .column2 div {
    padding: 0;
  }

  .group  {
    grid-template-columns: 1fr 0 0;
    grid-template-rows: 33% 10 62%;
    .column1 {
      grid-row: 1;
    }

    .column2 {
      grid-row: 3;
    }
  }

  .groupReverse{
    grid-template-columns: 1fr 0 0;
    grid-template-rows: 62% 10 33%;
    .column1 {
      grid-row: 3;
    }
    .column2 {
      grid-row: 1;
    }
  }
}
