@import '/styles/colors';
@import '/styles/mixins';

.frame {
  display: flex;
  flex-direction: row;
  height: fit-content;

  .frametext {
    display: flex;
    flex-direction: column;
    word-wrap: normal;
    justify-content: center;
    flex: 0.4;
  }

  .title {
    @include heading-34;
    color: $gray-01;
  }
  .description {
    @include body-16;
    color: $gray-04;
  }

  .frameImage {
    position: relative;
    flex: 0.7;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .frameImage > * + * {
    margin-left: 40px;
  }

  .icon1 {
    align-self: flex-end;
    margin-bottom: 50px;
    margin-left: 220px;
    border: 1px solid $green-02;
  }
  .icon2 {
    align-self: center;
    margin-right: 8.4%;
    background-color: rgb(253, 197, 109, 0.3);
  }

  .icon2,
  .icon1 {
    width: 10.17%;
    height: 17.28%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    * {
      object-fit: none;
    }
  }
  .card1 {
    width: 20.35%;
    height: fit-content;
    min-height: 28.73%;
    margin-top: 5.7%;
    background-color: $green-03;
  }

  .card4 {
    width: 22.55%;
    min-height: 35.33%;
    height: fit-content;
    margin-top: 7.4%;
    background-color: $green-03;
  }
  .card4,
  .card3,
  .card2,
  .card1 {
    border-radius: 8px;
    padding: 30px 10px;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;

    .title {
      @include heading-34;
      font-size: 30px;
      font-weight: 600;
      color: $gray-01;
    }
    .description {
      @include body-16;
      color: $gray-04;
    }
  }

  .card2 {
    width: 39.4%;
    min-height: 58.72%;
    height: fit-content;

    margin-left: 20px;
    background-color: $blue-04;
    padding: 50px;
    .title {
      font-size: 68px;
    }
    .description {
      padding: 0 20px;
    }
  }

  .card3 {
    width: 24.55%;
    min-height: 40.12%;
    height: fit-content;
    background-color: $blue-04;
    .title {
      font-size: 30px;
    }
  }
}
@media screen and (max-width: 1270px) {
  .frame {
    .frameImage {
      justify-content: space-between;
    }
    .frameImage > * + * {
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .frame {
    .frametext {
      .title {
        @include heading-34;
        font-size: 24px;
        font-weight: 600;
        padding: 0 30px;
        color: $gray-01;
      }
      .description {
        padding: 22px 10px 24px;
      }
    }

    flex-direction: column;
    height: fit-content;
    text-align: center;

    .icon2,
    .icon1 {
      width: 29%;
      height: 91px;
    }
    .card4,
    .card3 {
      width: 65%;
    }

    .card1 {
      flex-direction: row;
      div {
        padding: 5px;
      }
    }

    .card1,
    .card2 {
      width: 100%;
    }
    .card2 {
      height: 270px;
    }

    .icon2 {
      align-self: flex-start;
    }

    .icon1,
    .icon2,
    .card1,
    .card2,
    .card3,
    .card4 {
      margin: 10px 0;
    }
  }
}
