@import '/styles/colors';
@import '/styles/mixins';

.description{
  @include heading-30;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: $gray-01;
  padding-bottom: 12px;
}
.subDescription{
  @include body-16;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: $gray-04;
  padding-bottom: 24px;
}
.component {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}