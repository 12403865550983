@import '/styles/colors';
@import '/styles/mixins';

.fullCard {
  height: fit-content;
  background-color: $blue-01;
  position: relative;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  padding: 75px 115px;
}

.fullCard > * + * {
  margin-left: 5%;
}

.simulator {
  user-select: none;
  width: fit-content;
  background-color: white;
  border-radius: 8px;
  padding: 37px 45px 48px 45px;

  .title {
    @include heading-30-bold;
    color: $gray-01;
  }
}

.investmentTime {
  display: flex;
  align-items: center;
  color: $blue-02;
}

.investmentTimeValue {
  @include heading-20-bold;
  color: $blue-02;
  margin: 8px 0px 0px 5px;
  min-width: 68px;
}

.investmentTimeCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 6px;
  div[role='label'] {
    @include body-14;
    color: $gray-03;
    margin: 12px 12px 12px 0;
  }
}

.valueWithSlider {
  width: 100%;
  button {
    @include heading-18;
  }
  div[role='group'] {
    margin: 10px 0 6px;
    display: flex;
    flex-direction: row;

    label {
      @include body-14;
      color: $gray-03;
      margin: 12px 12px 12px 0;
      white-space: nowrap;
    }
    input {
      @include heading-20-bold;
      text-align: end;
    }
  }
}

.simulatorFrame {
  max-width: fit-content;
  justify-content: space-between;
}

.simulatorFrame > * + * {
  margin-top: 10px;
}
.chartFrame {
  width: 60%;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  max-width: fit-content;

  color: $blue-03;
}

@media (max-width: 1300px) {
  .fullCard {
    padding: 75px 35px;
  }
}

@media (max-width: 1024px) {
  .fullCard {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 75px 17px;
    align-items: center;
  }

  .fullCard > * + * {
    margin-left: 0;
  }
  .bar {
    font-size: 90%;
    width: 20%;
  }
  .chartInfos {
    border-bottom: 1px solid rgba($blue-03, 0.23);
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .chartInfo {
    width: 100px;
    font-size: 12px;
  }
  .chartFrame {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .chart {
    height: 300px;
  }

  .info {
    text-align: center;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .bar {
    font-size: 80%;
    width: 20%;
  }
  .chartInfo {
    width: 80px;
    font-size: 9px;
  }
}

@media (max-width: 500px) {
  .investmentTimeValue {
    font-size: 16px;
    min-width: 56px;
  }

  .bar {
    font-size: 8px;
    width: 20%;
  }
  .chartInfo {
    width: 80px;
    font-size: 9px;
  }
  .content {
    width: 100%;
    max-width: 100%;
  }
}
